import React, { useEffect, useState } from 'react';
import { Prompt, useHistory } from 'react-router';
import { Location } from 'history';
import ConfirmModal from '../modals/confirm-modal';
import { LeaveRoutePromptProps } from './interfaces';
import { MODAL_TEXTS } from './utils';
import { resetScanData } from '../../views/patient-scan-request/utils';

const LeaveRoutePrompt: React.FunctionComponent<LeaveRoutePromptProps> = ({ when, onLeave }) => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [nextLocation, setNextLocation] = useState<Location | null>(null);
  const [hasConfirmedNavigation, setHasConfirmedNavigation] = useState<boolean>(false);
  const history = useHistory();

  useEffect(() => {
    if (hasConfirmedNavigation && nextLocation) {
      onLeave();
      history.push(nextLocation.pathname);
    }
  }, [hasConfirmedNavigation]);

  useEffect(() => {
    setHasConfirmedNavigation(false);
  }, [isModalVisible]);

  const handleCloseModal = (value: boolean): void => {
    setIsModalVisible(false);
    if (value && nextLocation) {
      setHasConfirmedNavigation(true);
      resetScanData();
    }
  };

  const handleBlockNavigation = (location: Location): string | boolean => {
    if (location.pathname !== '/login' && !hasConfirmedNavigation) {
      setNextLocation(location);
      setIsModalVisible(true);
      return false;
    }
    return true;
  };

  return (
    <>
      <Prompt when={when} message={handleBlockNavigation} />
      {isModalVisible && <ConfirmModal info={MODAL_TEXTS} onClose={handleCloseModal} />}
    </>
  );
};

export default LeaveRoutePrompt;
